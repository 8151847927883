import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryAgentsdelamort from "../components/galleryAgentsdelamort"

const Agentsdelamort = () => (
  <Layout>
    <SEO title="Agents de la mort" />
    <h1>Archive or personal mnemosyne of photography</h1>
    <p>This social network user account existing in Instagram and Twitter is part of a research and archival project where I've been working since 2018. It's an instrospection on my ways to see photography, art, and politics. Also it is a way to share my knowledge and pictures that have never been published in the Internet before.</p>
    <p>With around 30k followers in Instagram it is one of the biggest cultural accounts in Spanish in that social network. You can see it <a href="https://www.instagram.com/agentsdelamort/" target="__blank">here</a>.</p>
  	
  	<a href="https://www.instagram.com/agentsdelamort/" target="__blank">
	    <div style={{"margin": "0 auto", "text-align": "center"}}>
	    	<GalleryAgentsdelamort />
	    </div>
    </a>
  </Layout>
)

export default Agentsdelamort